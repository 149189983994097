@import 'style';

$color: $blue-midnight;

.button {
	cursor: pointer;
	color: white;
	font-size: 16px;
	line-height: 24px;
	font-weight: 500;
	text-transform: uppercase;
	background-color: $color;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 16px 25px;
	border: 2px solid transparent;
	transition: all 0.25s ease-out;

	@include media($ipad-land) {
		padding: 16px 50px;
	}

	&:hover {
		background-color: $hover;

		.button {
			&-icon.mod-arrow_diagonal {
				transform: translate(5px, -5px);
			}
		}
	}

	&.mod-outline {
		color: $color;
		border-color: $color;
		background-color: transparent;

		&:focus,
		&:hover {
			color: white;
			background-color: $color;
		}
	}

	&-text,
	&-icon {
		& + & {
			margin-left: 10px;
		}
	}

	&-text {
		text-align: left;
	}

	&-icon {
		flex: 0 0 auto;
		font-size: 10px;
		line-height: 1;
		transition: transform 0.25s ease-out;
	}
}
