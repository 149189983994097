@import 'style';

.title {
	color: $blue-midnight;
	word-break: break-word;

	&.mod-h1 {
		font-size: 40px;
		line-height: 44px;

		@include media($desktop) {
			font-size: 56px;
			line-height: 59px;
		}
	}

	&.mod-h2 {
		@include heading2;
	}

	&.mod-h3 {
		@include heading3;
	}

	&.mod-h4 {
		@include heading4;
	}

	&.mod-h5 {
		@include heading5;
	}

	&.mod-uppercase {
		text-transform: uppercase;
	}

	&.mod-dots {
		position: relative;
		display: inline;
		white-space: pre-line;
	}

	&.mod-inherit {
		color: inherit;
		text-transform: inherit;
	}
}

.wrapper {
	position: relative;

	&-dots {
		position: absolute;
		z-index: 1;
		left: 45px;
		top: -45px;
		width: calc(450px / 1.5);
		height: calc(335px / 1.5);

		@include media($ipad-land) {
			top: -90px;
			width: 450px;
			height: 335px;

			.mod-large & {
				height: 550px;
			}
		}

		@include media($desktop) {
			left: 105px;
		}
	}
}
