@import './styles/heading.scss';
@import 'include-media/dist/_include-media.scss';

$blue-midnight: #36337d;
$turquoise: #52bdc2;
$gray-dark: #3c3c3b;
$background: #eaeaea;
$border: #cbcbcb;
$hover: #504c8c;

$breakpoints: (
	tablet: 600px,
	ipad-port: 768px,
	ipad-land: 1024px,
	desktop: 1200px,
	large: 1440px
);

$tablet: '>=tablet';
$ipad-port: '>=ipad-port';
$ipad-land: '>=ipad-land';
$desktop: '>=desktop';
$large: '>=large';

@mixin ie-only() {
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		@content;
	}
}
