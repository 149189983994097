@mixin heading2 {
	font-size: 36px;
	line-height: 40px;

	@include media($desktop) {
		font-size: 52px;
		line-height: 63px;
	}
}

@mixin heading3 {
	font-size: 28px;
	line-height: 34px;
}

@mixin heading4 {
	font-size: 24px;
	line-height: 29px;
	font-weight: 600;
}

@mixin heading5 {
	font-size: 18px;
	line-height: 27px;
}
