@import 'style';

.page404 {
	margin: 75px 0;
	text-align: center;

	@include media($desktop) {
		margin: 125px 0;
	}

	&-text {
		max-width: 640px;
		margin: 25px auto 0;
	}

	&-button {
		margin-top: 25px;
	}
}
